/* template */
#main-menu .logo {
  height: 40px;
}

#hero .logo {
  height: 60px;
}

#hero .carousel-caption {
  bottom: unset;
}

#footer .logo {
  height: 40px;
}

.offcanvas-title .logo {
  height: 40px;
}

.ratio-header {
  padding-bottom: 25%;
}

.carousel-control-next,
.carousel-control-prev {
  width: 30px;
}

.course-cover .fs-1 {
  font-size: 4rem;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: #000;
}