@font-face {
  font-family: 'Avenir';
  src: url('./Fonts/Avenir-Light.eot'); /* IE9 Compat Modes */
  src: url('./Fonts/Avenir-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Fonts/Avenir-Light.woff') format('woff'), /* Modern Browsers */
       url('./Fonts/Avenir-Light.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Fonts/Avenir-Light.svg#Avenir-Light') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: lighter;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'Avenir';
  src: url('./Fonts/Avenir-LightOblique.eot'); /* IE9 Compat Modes */
  src: url('./Fonts/Avenir-LightOblique.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Fonts/Avenir-LightOblique.woff') format('woff'), /* Modern Browsers */
       url('./Fonts/Avenir-LightOblique.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Fonts/Avenir-LightOblique.svg#Avenir-LightOblique') format('svg'); /* Legacy iOS */
  font-style: italic;
  font-weight: lighter;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'Avenir';
  src: url('./Fonts/Avenir-Roman.eot'); /* IE9 Compat Modes */
  src: url('./Fonts/Avenir-Roman.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Fonts/Avenir-Roman.woff') format('woff'), /* Modern Browsers */
       url('./Fonts/Avenir-Roman.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Fonts/Avenir-Roman.svg#Avenir-Roman') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'Avenir';
  src: url('./Fonts/Avenir-Oblique.eot'); /* IE9 Compat Modes */
  src: url('./Fonts/Avenir-Oblique.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Fonts/Avenir-Oblique.woff') format('woff'), /* Modern Browsers */
       url('./Fonts/Avenir-Oblique.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Fonts/Avenir-Oblique.svg#Avenir-Oblique') format('svg'); /* Legacy iOS */
  font-style: italic;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'Avenir';
  src: url('./Fonts/Avenir-Heavy.eot'); /* IE9 Compat Modes */
  src: url('./Fonts/Avenir-Heavy.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Fonts/Avenir-Heavy.woff') format('woff'), /* Modern Browsers */
       url('./Fonts/Avenir-Heavy.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Fonts/Avenir-Heavy.svg#Avenir-Heavy') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'Avenir';
  src: url('./Fonts/Avenir-HeavyOblique.eot'); /* IE9 Compat Modes */
  src: url('./Fonts/Avenir-HeavyOblique.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Fonts/Avenir-HeavyOblique.woff') format('woff'), /* Modern Browsers */
       url('./Fonts/Avenir-HeavyOblique.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Fonts/Avenir-HeavyOblique.svg#Avenir-HeavyOblique') format('svg'); /* Legacy iOS */
  font-style: italic;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'Avenir';
  src: url('./Fonts/Avenir-Black.eot'); /* IE9 Compat Modes */
  src: url('./Fonts/Avenir-Black.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Fonts/Avenir-Black.woff') format('woff'), /* Modern Browsers */
       url('./Fonts/Avenir-Black.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Fonts/Avenir-Black.svg#Avenir-Black') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: bolder;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'Avenir';
  src: url('./Fonts/Avenir-BlackOblique.eot'); /* IE9 Compat Modes */
  src: url('./Fonts/Avenir-BlackOblique.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Fonts/Avenir-BlackOblique.woff') format('woff'), /* Modern Browsers */
       url('./Fonts/Avenir-BlackOblique.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Fonts/Avenir-BlackOblique.svg#Avenir-BlackOblique') format('svg'); /* Legacy iOS */
  font-style: italic;
  font-weight: bolder;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'SukhumvitSet';
  src: url('./Fonts/SukhumvitSet-Thin.eot'); /* IE9 Compat Modes */
  src: url('./Fonts/SukhumvitSet-Thin.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Fonts/SukhumvitSet-Thin.woff') format('woff'), /* Modern Browsers */
       url('./Fonts/SukhumvitSet-Thin.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Fonts/SukhumvitSet-Thin.svg#SukhumvitSet-Thin') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: 100;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'SukhumvitSet';
  src: url('./Fonts/SukhumvitSet-Light.eot'); /* IE9 Compat Modes */
  src: url('./Fonts/SukhumvitSet-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Fonts/SukhumvitSet-Light.woff') format('woff'), /* Modern Browsers */
       url('./Fonts/SukhumvitSet-Light.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Fonts/SukhumvitSet-Light.svg#SukhumvitSet-Light') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: lighter;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'SukhumvitSet';
  src: url('./Fonts/SukhumvitSet-Medium.eot'); /* IE9 Compat Modes */
  src: url('./Fonts/SukhumvitSet-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Fonts/SukhumvitSet-Medium.woff') format('woff'), /* Modern Browsers */
       url('./Fonts/SukhumvitSet-Medium.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Fonts/SukhumvitSet-Medium.svg#SukhumvitSet-Medium') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'SukhumvitSet';
  src: url('./Fonts/SukhumvitSet-SemiBold.eot'); /* IE9 Compat Modes */
  src: url('./Fonts/SukhumvitSet-SemiBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Fonts/SukhumvitSet-SemiBold.woff') format('woff'), /* Modern Browsers */
       url('./Fonts/SukhumvitSet-SemiBold.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Fonts/SukhumvitSet-SemiBold.svg#SukhumvitSet-SemiBold') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'SukhumvitSet';
  src: url('./Fonts/SukhumvitSet-Bold.eot'); /* IE9 Compat Modes */
  src: url('./Fonts/SukhumvitSet-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Fonts/SukhumvitSet-Bold.woff') format('woff'), /* Modern Browsers */
       url('./Fonts/SukhumvitSet-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Fonts/SukhumvitSet-Bold.svg#SukhumvitSet-Bold') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: bolder;
  text-rendering: optimizeLegibility;
}
