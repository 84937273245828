@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@100;200;300;400;500;600;700&display=swap');

:root {
  /* Breakpoint Variable */
  --breakpoint-xxl: '1400px';
  --breakpoint-xl: '1200px';
  --breakpoint-lg: '992px';
  --breakpoint-md: '768px';
  --breakpoint-sm: '576px';

  /* Color Variable */
  --color-primary: #00423E;
  --color-secondary: #FF5A3C;
  --color-tertiary: #1B2252;
  --color-black: #1E1E1E;
  --color-dark: #565F67;
  --color-gray: #818F9A;
  --color-light: #AAB3BB;
  --color-lighter: #D4D8DC;
  --color-white: #FFF;

  /* Font Variable */
  --font-header: 'Avenir', sans-serif;
  --font-default: 'IBM Plex Sans Thai', sans-serif; /* 'SukhumvitSet', sans-serif */
  --font-handwrite: 'IBM Plex Sans Thai', sans-serif; /* 'SukhumvitSet', sans-serif */

}